<template lang="pug">
a-form-model(ref="form" :model="detail" :rules="rules" :label-col="{ span: 3 }" :wrapper-col="{ span: 14, offset: 1 }")

  a-form-model-item.m-b-10(label="账号" prop="account")
    a-input(v-model="detail.account" placeholder="请输入账号")

  a-form-model-item.m-b-10(label="手机" prop="mobile")
    a-input(v-model="detail.mobile" placeholder="请输入手机")

  a-form-model-item.m-b-10(label="邮箱" prop="email")
    a-input(v-model="detail.email" placeholder="请输入邮箱")

  a-form-model-item.m-b-10(label="密码" prop="password")
    a-input(type="password" v-model="detail.password" placeholder="请输入密码")

  a-form-model-item.m-b-10(label="简介" prop="autograph")
    a-input(type="textarea" v-model="detail.autograph" :autoSize="{ minRows: 2, maxRows: 5 }")

  a-form-model-item.m-b-10(:wrapper-col="{ span: 14, offset: 4 }")
    a-button(type="primary" :loading="loading" @click="handleSubmit('form')") 提交
    a-button.m-l-10(type="default" @click="handleReset('form')") 重置

  //- .flex.fs-11
    pre(style="width: 33.33%; overflow: hidden;") {{ rules }}
    pre(style="width: 33.33%; overflow: hidden;") {{ data }}
    pre(style="width: 33.33%; overflow: hidden;") {{ detail }}
</template>

<script>
export default {
  name: 'UserFormMini',
  props: {
    data: {
      type: Object,
      default: null
    },
    //- rules: {
    //-   type: Object,
    //-   default: null
    //- }
  },
  data() {
    return {
      rules: {
        account: [
          { required: true, message: '账号必须', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '密码必须', trigger: 'blur' }
        ]
      },
      loading: false,
      detail: {}
    }
  },
  methods: {
    /*********************************************************
     * @description 获取数据详情
     *********************************************************/
    async getDetail() {
      if (!this.data.id) return;
      try {
        this.detail = await this.$http.get(`user/${this.data.id}`);
      } catch(err) {
        this.$message.error(err.message);
      }
    },

    /*********************************************************
     * @description 表单提交
     *********************************************************/
    handleSubmit(name) {
      this.$refs[name].validate(async valid => {
        if (!valid) return;
        try {
          this.loading = true;

          const method = this.detail.id ? 'put' : 'post';
          const url = this.detail.id ? `user/${this.detail.id}` : `user`;

          await this.$http[method](url, Object.assign({ group: this.data.group }, { status: 'enabled' }, this.detail));
          
          this.loading = false;
          this.$message.success('提交成功!');
          this.$refs[name].resetFields();
          
          this.$parent.$parent.$emit('ok');
          // this.$router.replace({ name: `User Management`, query: { active_tab: 'user' } });
        } catch (err) {
          this.loading = false;
          this.$message.error(err.message);
        }
      });
    },
    
    /*********************************************************
     * @description 重置表单
     *********************************************************/
    handleReset(name) {
      //- this.$parent.$parent.$emit('ok');
      //- this.$parent.$parent.$emit('close');
      //- this.$parent.$parent.$emit('cancel');
      //- this.$parent.$parent.close();

      this.$refs[name].resetFields();
    }
  },
  mounted() {
    this.getDetail();
  }
}
</script>